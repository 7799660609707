@font-face {
font-family: '__GTAmericaRegular_0f41da';
src: url(/_next/static/media/5a5e48040f1827e4-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__GTAmericaRegular_Fallback_0f41da';src: local("Arial");ascent-override: 98.73%;descent-override: 19.65%;line-gap-override: 0.00%;size-adjust: 101.79%
}.__className_0f41da {font-family: '__GTAmericaRegular_0f41da', '__GTAmericaRegular_Fallback_0f41da', arial, sans-serif;font-weight: 400;font-style: normal
}

@font-face {
font-family: '__GTAmericaMedium_2a534a';
src: url(/_next/static/media/78da0b225a0e780f-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__GTAmericaMedium_Fallback_2a534a';src: local("Arial");ascent-override: 95.49%;descent-override: 24.64%;line-gap-override: 0.00%;size-adjust: 104.72%
}.__className_2a534a {font-family: '__GTAmericaMedium_2a534a', '__GTAmericaMedium_Fallback_2a534a', arial, sans-serif;font-weight: 500;font-style: normal
}

@font-face {
font-family: '__GTAmericaRegularItalic_3724eb';
src: url(/_next/static/media/7da92a54e32dcd0e-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__GTAmericaRegularItalic_Fallback_3724eb';src: local("Arial");ascent-override: 97.90%;descent-override: 25.26%;line-gap-override: 0.00%;size-adjust: 102.14%
}.__className_3724eb {font-family: '__GTAmericaRegularItalic_3724eb', '__GTAmericaRegularItalic_Fallback_3724eb', arial, sans-serif;font-weight: 400;font-style: normal
}

@font-face {
font-family: '__GTAmericaBold_719f32';
src: url(/_next/static/media/445cee777300297f-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__GTAmericaBold_Fallback_719f32';src: local("Arial");ascent-override: 92.97%;descent-override: 18.50%;line-gap-override: 0.00%;size-adjust: 108.10%
}.__className_719f32 {font-family: '__GTAmericaBold_719f32', '__GTAmericaBold_Fallback_719f32', arial, sans-serif;font-weight: 700;font-style: normal
}

@font-face {
font-family: '__GTAmericaCompressedBlack_cb4316';
src: url(/_next/static/media/46f648c13e2b6e0c-s.p.woff) format('woff');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__GTAmericaCompressedBlack_Fallback_cb4316';src: local("Arial");ascent-override: 119.72%;descent-override: 30.89%;line-gap-override: 0.00%;size-adjust: 83.53%
}.__className_cb4316 {font-family: '__GTAmericaCompressedBlack_cb4316', '__GTAmericaCompressedBlack_Fallback_cb4316', arial, sans-serif;font-weight: 900;font-style: normal
}

@font-face {
font-family: '__GTAmericaExtendedBold_b0d8ba';
src: url(/_next/static/media/dbae9205dec2a3fe-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__GTAmericaExtendedBold_Fallback_b0d8ba';src: local("Arial");ascent-override: 77.94%;descent-override: 20.11%;line-gap-override: 0.00%;size-adjust: 128.31%
}.__className_b0d8ba {font-family: '__GTAmericaExtendedBold_b0d8ba', '__GTAmericaExtendedBold_Fallback_b0d8ba', arial, sans-serif;font-weight: 700;font-style: normal
}

@font-face {
font-family: '__RecoletaMedium_a70616';
src: url(/_next/static/media/1d5feaeb65837703-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}@font-face {font-family: '__RecoletaMedium_Fallback_a70616';src: local("Arial");ascent-override: 99.00%;descent-override: 35.64%;line-gap-override: 0.00%;size-adjust: 101.01%
}.__className_a70616 {font-family: '__RecoletaMedium_a70616', '__RecoletaMedium_Fallback_a70616', Georgia, serif;font-weight: 500;font-style: normal
}

@font-face {
font-family: '__RecoletaBold_4af1b2';
src: url(/_next/static/media/33c7adb556947925-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__RecoletaBold_Fallback_4af1b2';src: local("Arial");ascent-override: 95.48%;descent-override: 34.37%;line-gap-override: 0.00%;size-adjust: 104.74%
}.__className_4af1b2 {font-family: '__RecoletaBold_4af1b2', '__RecoletaBold_Fallback_4af1b2', Georgia, serif;font-weight: 700;font-style: normal
}

@font-face {
font-family: '__RecoletaSemiBold_adf946';
src: url(/_next/static/media/bfe49b323eec1e59-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__RecoletaSemiBold_Fallback_adf946';src: local("Arial");ascent-override: 97.19%;descent-override: 34.99%;line-gap-override: 0.00%;size-adjust: 102.89%
}.__className_adf946 {font-family: '__RecoletaSemiBold_adf946', '__RecoletaSemiBold_Fallback_adf946', Georgia, serif;font-weight: 600;font-style: normal
}

